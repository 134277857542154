import { Controller } from 'stimulus';

export default class extends Controller {
  filterSelected(event) {
    const params = Array.from(this.selected).map(filter => filter.value);
    Turbolinks.visit(`${this.filterPath}?status=${params.join(',')}`)
  }

  get filterPath() {
    return this.element.dataset.filterPath;
  }

  get selected() {
    return this.element.querySelectorAll('input[type="checkbox"].filter:checked');
  }
}
