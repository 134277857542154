import './component.scss';

class CssRegistry {
  get toggle() {
    return 'hidden';
  }

  get entering() {
    return ['ease-out', 'duration-100'];
  }

  get leaving() {
    return ['ease-in', 'duration-75'];
  }

  get active() {
    return [];
  }

  get invisible() {
    return ['opacity-0', 'scale-95'];
  }

  get visible() {
    return ['opacity-100', 'scale-100'];
  }
}

export { CssRegistry };

