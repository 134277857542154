import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['button', 'form'];

  toggle() {
    if (this.hidden) {
      return this.onShow();
    }

    return this.onHide();
  }

  show() {
    return this.onShow();
  }

  get hidden() {
    return this.menuTarget.classList.contains(this.css.toggle);
  }

  get visible() {
    return !this.hidden;
  }

  onShow() {
    this.buttonTarget.classList.add('hidden');
    this.formTarget.classList.remove('hidden');
  }

  onHide() {
    this.buttonTarget.classList.remove('hidden');
    this.formTarget.classList.add('hidden');
  }
}
