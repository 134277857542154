import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container'];

  connect() {
    this.toggleClass    = 'hidden';
    this.backgroundId   = 'modal-background';
    this.backgroundHtml = this.defaultBackgroundHTML();
  }

  disconnect() {
    this.close();
  }

  open(e) {
    e.target.blur();
    this.lockScroll();
    this.containerTarget.classList.remove(this.toggleClass);
    document.body.insertAdjacentHTML('beforeend', this.backgroundHtml);
    this.background = document.querySelector(`#${this.backgroundId}`);
  }

  close(e) {
    this.unlockScroll();
    this.containerTarget.classList.add(this.toggleClass);

    if (this.background) {
      this.background.remove()
    }
  }

  closeBackground(e) {
    if (e.target === this.containerTarget) {
      this.close(e);
    }
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27 && !this.containerTarget.classList.contains(this.toggleClass)) {
      this.close(e);
    }
  }

  defaultBackgroundHTML() {
    return `<div id="${this.backgroundId}"
                 class="fixed top-0 left-0 w-full h-full"
                 style="background-color: rgba(0, 0, 0, 0.4);
                        backdrop-filter: blur(4px);
                        z-index: 9998;">
            </div>`;
  }

  lockScroll() {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    document.body.style.paddingRight = `${scrollbarWidth}px`;
    this.saveScrollPosition();
    document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden');
    document.body.style.top = `-${this.scrollPosition}px`;
  }

  unlockScroll() {
    document.body.style.paddingRight = null;
    document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden');
    this.restoreScrollPosition();
    document.body.style.top = null;
  }

  saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop;
  }

  restoreScrollPosition() {
    document.documentElement.scrollTop = this.scrollPosition;
  }
}
