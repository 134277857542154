import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['startDate', 'startTime'];

  get fields() {
    return new Map([
      ['start_date', this.startDateTarget],
      ['start_time', this.startTimeTarget],
    ]);

  }

  onSuccess(event) {
    const [data, status, xhr] = event.detail;

    console.log({ data, status, xhr });
    Turbolinks.visit(data.location);
  }

  onError(event) {
    const [data, status, xhr] = event.detail;

    this.reset();
    this.show(data.errors);
  }

  reset() {
    for (const [_, field] of this.fields.entries()) {
      const errors = field.querySelector('.error');
      errors && errors.remove();
    }
  }

  show(errors) {
    if (!errors) {
      return;
    }

    for (const [error, message] of Object.entries(errors)) {
      const field = this.fields.get(error);
      field && field.insertAdjacentHTML("beforeend", `<span class="error">${ message }</span>`)
    }
  }
}
