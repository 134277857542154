import { Controller } from 'stimulus';
import { CssRegistry } from './component';

export default class extends Controller {
  static targets = ['icon', 'menu'];

  initialize() {
    this.css = new CssRegistry();
  }

  connect() {
    if (this.isPreview) {
      this.menuTarget.classList.add('hidden');
    }
  }

  toggle() {
    if (this.hidden) {
      return this.onShow();
    }

    return this.onHide();
  }

  show() {
    return this.onShow();
  }

  hide() {
    return this.onHide();
  }

  close(event) {
    if (
      (this.element.contains(event.target) || this.datePickerPresent) &&
      this.visible
    ) {
      return;
    }

    this.onHide();
  }

  implode(event) {
    this.onHide();
  }

  focusIcon() {
    this.iconTarget.focus();
  }

  get datePickerPresent() {
    return this.element.querySelector('[data-form--datepicker--component-target="picker"]');
}

  get activeTarget() {
    return (
      (this.data.has('activeTarget') &&
        document.querySelector(this.data.get('activeTarget'))) ||
      this.element
    );
  }

  get hidden() {
    return this.menuTarget.classList.contains(this.css.toggle);
  }

  get visible() {
    return !this.hidden;
  }

  get isPreview() {
    return document.documentElement.hasAttribute('data-turbolinks-preview');
  }

  onShow() {
    this.menuTarget.classList.remove(this.css.toggle);
    this.menuTarget.classList.add(...this.css.entering);

    function entering() {
      this.activeTarget.classList.add(...this.css.active);
      this.menuTarget.classList.remove(...this.css.invisible);
      this.menuTarget.classList.add(...this.css.visible);

      function activate() {
        this.menuTarget.classList.remove(...this.css.entering);
      }

      window.setTimeout(activate.bind(this), 100);
    }

    window.setTimeout(entering.bind(this));
  }

  onHide() {
    function leaving() {
      this.activeTarget.classList.remove(...this.css.active);
      this.menuTarget.classList.remove(...this.css.visible);
      this.menuTarget.classList.add(...this.css.leaving);
      this.menuTarget.classList.add(...this.css.invisible);

      function deactivate() {
        this.menuTarget.classList.remove(...this.css.leaving);
        this.menuTarget.classList.add(this.css.toggle);
      }

      window.setTimeout(deactivate.bind(this), 75);
    }

    window.setTimeout(leaving.bind(this));
  }
}
