import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';

export default class extends Controller {
  static targets = ['picker'];

  connect() {
    flatpickr(this.pickerTarget, {
      enableTime: true,
      noCalendar: true,
      dateFormat: "h:i K",
      time_24hr: false,
    });
  }
}
