// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const controllers = require.context("controllers", true, /_controller\.js$/);
const components = require.context("../../components", true, /_controller.js$/);
const presenters = require.context("../../presenters", true, /_controller.js$/);
const dependencies = definitionsFromContext(controllers).concat(definitionsFromContext(components)).concat(definitionsFromContext(presenters));

application.load(dependencies);
