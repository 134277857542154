import { Controller } from 'stimulus';

export default class extends Controller {
  filterAvailability() {
    return this.filterBy({ param: 'availability' });
  }

  filterSkills() {
    return this.filterBy({ param: 'skills' });
  }

  filterStatus() {
    return this.filterBy({ param: 'status' });
  }

  filterBy({ param }) {
    const selected = this.element.querySelectorAll(`input[type="checkbox"].${param}:checked`);
    const values   = Array.from(selected).map(filter => filter.value);
    const url      = this.urlFor({ param, values });

    Turbolinks.visit(url);
  }

  urlFor({ param, values }) {
    const url = new URL(window.location.href);

    url.searchParams.set(param, values.join(','));
    url.searchParams.delete('page');

    return url.toString();
  }
}
