import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  connect() {
    if (this.isPreview) {
      this.element.classList.add('hidden');
    }

    const timeout = (this.timeout * 1000) + 500;

    function fadeIn() {
      this.element.classList.remove('hidden');

      function complete() {
        this.element.classList.add('transform', 'ease-out', 'duration-300', 'transition', 'translate-y-2', 'opacity-0', 'sm:translate-y-0', 'sm:translate-x-2');
        this.element.classList.add('translate-y-0', 'opacity-100', 'sm:translate-x-0');
      }

      setTimeout(complete.bind(this), 100);
    }

    setTimeout(fadeIn.bind(this), 500);
    this.currentTimeout = setTimeout(this.close.bind(this), timeout);
  }

  stop() {
    clearTimeout(this.currentTimeout);
    this.currentTimeout = null;
  }

  close() {
    this.element.classList.remove('transform', 'ease-out', 'duration-300', 'translate-y-2', 'opacity-0', 'sm:translate-y-0', 'sm:translate-x-2', 'translate-y-0', 'sm:translate-x-0');
    this.element.classList.add('ease-in', 'duration-100');

    function fadeOut() {
      this.element.classList.add('opacity-0');

      function remove() {
        this.element.remove();
      }

      setTimeout(remove.bind(this), 300);
    }

    setTimeout(fadeOut.bind(this), 100);
  }

  get timeout() {
    return parseInt(this.element.dataset.timeout);
  }

  get isPreview() {
    return document.documentElement.hasAttribute('data-turbolinks-preview');
  }
}
