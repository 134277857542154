
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form'];

  toggleForm(e) {
    this.formTarget.classList.toggle('hidden');
    this.formTarget.parentElement.classList.toggle('active');
  }
}
