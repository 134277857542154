import { Controller } from 'stimulus';
import Litepicker from 'litepicker';

export default class extends Controller {
  static targets = ['picker'];

  connect() {
    new Litepicker({
      element: this.pickerTarget,
      setup: (picker) => {
        picker.on('selected', (date1, date2) => {
          window.dispatchEvent(new CustomEvent('datepicker:selected', {
            detail: {
              date1,
              date2
            }
          }));
        });
      }
    });
  }
}
