import { Controller } from 'stimulus';
import IMask from 'imask';

export default class extends Controller {
  initialize() {
    IMask(this.element, {
      mask: '(000) 000-0000',
      lazy: false,
    });
  }
}
