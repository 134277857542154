import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['lat', 'long'];

  coordinatesFound(event) {
    const { detail } = event;

    this.latTarget.value = detail.lat;
    this.longTarget.value = detail.lng;
  }
}
