import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["name", "radioButton", "next"];

    connect() {
        this.nameTarget.addEventListener('input', this.checkFormValidity.bind(this));
        this.radioButtonTargets.forEach((radioButton) => {
            radioButton.addEventListener('change', this.checkFormValidity.bind(this));
        });
        this.checkFormValidity();
    }

    checkFormValidity() {
        const isNameValid = this.hasNameTarget ? this.nameTarget.value.trim() !== '' : false;
        const anyRadioButtonChecked = this.radioButtonTargets.some(
            (radioButton) => radioButton.checked
        );

        if (isNameValid && anyRadioButtonChecked) {
            this.nextTarget.disabled = false;
        } else {
            this.nextTarget.disabled = true;
        }
    }
}
