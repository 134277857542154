
const components = require.context('../../components', true, /component\.js$/)
const presenters = require.context('../../presenters', true, /[presenter|component]\.js$/)

function include(context) {
  context.keys().forEach(context)
}

include(components);
include(presenters);
