import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['search'];

  connect() {
    if (typeof(google) == "undefined") {
      return;
    }

    this.ready();
  }

  disconnect() {
    this.autocomplete = null;
  }

  ready() {
    this.autocomplete = new google.maps.places.Autocomplete(this.searchTarget);
    this.autocomplete.setFields(['address_components', 'geometry', 'icon', 'name']);
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this));
  }

  placeChanged(event) {
    let place = this.autocomplete.getPlace();

    if (!place.geometry) {
      console.error(`No details available for input: ${place.name}`);
      return;
    }

    window.dispatchEvent(new CustomEvent('place--autocomplete:change', {
      detail: {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      }
    }));
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }
}
