import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['picker', 'preview'];


  open(event) {
    event.preventDefault();
    this.pickerTarget.click();
  }

  preview(event) {
    if (!this.previewTarget) {
      return;
    }

    const { target, detail } = event;
    const file   = target.files[0];
    const reader = new FileReader();

    function onload(e) {
      this.previewTarget.setAttribute('src', e.target.result);
    }

    reader.onload = onload.bind(this)
    reader.readAsDataURL(file);
  }
}
