import { Controller } from 'stimulus';

export default class extends Controller {
    connect() {
        const fieldGroups = document.querySelectorAll('.cursor-pointer');
        fieldGroups.forEach(fieldGroup => {
            const checkboxes = fieldGroup.querySelectorAll('input[type="checkbox"]');
            const badgeSpan = fieldGroup.querySelector('.badge-span');

            this.updateBadgeCount(checkboxes, badgeSpan);

            checkboxes.forEach((checkbox) => {
                checkbox.addEventListener('change', () => {
                    this.updateBadgeCount(checkboxes, badgeSpan);
                });
            });

        });
    }

    updateBadgeCount(checkboxes, badgeSpan) {
        const checkedCount = Array.from(checkboxes).filter(checkbox => checkbox.checked).length;
        badgeSpan.textContent = checkedCount;
        if(checkedCount == 0){
            badgeSpan.classList.add('hidden');
        } else {
            badgeSpan.classList.remove('hidden');
        }
    }

    toggle() {
        const id = this.element.dataset.targetId;
        const target = document.getElementById(id);
        target.classList.toggle('hidden');
    }
}
